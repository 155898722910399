import { graphql, Link } from "gatsby";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IconTwitter from "../../assets/icons/twitter.svg";
import IconSubscribe from "../../assets/icons/mail.svg";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import PostAuthor from "../components/PostAuthor";
import SEO from "../components/seo";
import SiteHeader from "../components/SiteHeader";
import colors from "../components/style/colors";
import P1 from "../components/typography/P1";
import P2 from "../components/typography/P2";
import H1 from "../components/typography/H1";
import H4 from "../components/typography/H4";
import Button from "../components/ui/Button";
import "../main.css";
import createTwitterLink from "../utils/createTwitterLink";
import Footer from "../components/Footer";
import ExitPopup from "../components/ExitPopup";
import H3 from "../components/typography/H3";
import { StyledClose } from "../components/Paywall";

const PageBody = styled.div`
  text-align: left;
  font-size: 1.15em;
  > p > .gatsby-resp-image-wrapper,
  > .gatsby-resp-image-figure,
  > p .gatsby-resp-image-figure,
  > .gatsby-resp-image-link,
  > p .gatsby-resp-image-link,
  > p > img {
    margin: 2em auto !important;
  }
  > p > .gatsby-resp-image-wrapper,
  > .gatsby-resp-image-figure,
  > p .gatsby-resp-image-figure,
  > .gatsby-resp-image-link,
  > p .gatsby-resp-image-link {
    @media (min-width: 1000px) {
      margin: 2em -3em !important;
    }
  }
  p,
  ul,
  ol,
  blockquote {
    font-family: Lora, "Palatino Linotype", "Book Antiqua", Palatino, Georgia, serif;
    color: ${colors.ultra_dark};
    margin: 0.3em 0 0.8em;
    font-weight: 400;
    line-height: 1.8;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.6em;
  }
  h4 {
    font-size: 1.2em;
  }
  h2,
  h3,
  h4 {
    color: ${colors.ultra_dark};
    margin-top: 1.2em;
    margin-bottom: 0.5em;
  }
  p > strong > em {
    font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: 600;
  }
  code {
    font-weight: 500;
    color: ${colors.accent2};
    background-color: rgba(208, 31, 25, 0.08);
    font-size: 1.1em;
    padding: 0.2em 0.5em;
    border-radius: 0.3em;
  }
  blockquote {
    padding-left: 1.5em;
    padding-right: 1.5em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-style: italic;
    > * {
      color: ${colors.darker};
    }
  }
  hr {
    border: none;
    border-bottom: 1px solid ${colors.lighter};
    margin: 2em 0;
  }
  a {
    text-decoration: none;
    color: ${colors.accent1};
    font-weight: 700;
    :hover {
      text-decoration: underline;
    }
  }
  figcaption {
    font-size: 0.8em;
    color: ${colors.dark};
    text-align: center;
    padding-top: 1em;
  }
  img {
    max-width: 100%;
    display: block;
  }

  .contribution-header {
    p > em {
      font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
      font-style: normal;
      color: ${colors.grey};
      a {
        color: inherit;
        font-weight: 400;
        text-decoration: underline;
      }
    }
    .gatsby-resp-image-link {
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      width: 2.5em;
      display: inline-block;
      margin: 0;
    }

    p:first-child {
      margin: 0;
      display: inline-block;
    }
    h2 {
      margin: 0 0 0 0.3em;
      position: relative;
      bottom: 0.25em;
      display: inline-block;
      a {
        font-weight: 400;
        color: ${colors.ultra_dark};
        :hover {
          text-decoration: none;
          color: ${colors.accent1};
        }
      }
    }
    @media (max-width: 700px) {
      font-size: 0.8em;
    }
  }
`;

const BlogPostFooter = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3em;
`;

const Buttons = styled.div`
  a {
    margin: 1em 0.5em;
  }
`;

const authors = {
  fabrizio: {
    name: "Fabrizio",
    twitter: "linuz90"
  },
  francesco: {
    name: "Francesco",
    twitter: "frankdilo"
  }
};

export default function Template(props) {
  const { data } = props;

  const { frontmatter, html } = data.thisPost;

  let thisPostIndex = data.allPosts.edges.findIndex(
    post => post.node.frontmatter.title == data.thisPost.frontmatter.title
  );

  const prevPost = data.allPosts.edges[thisPostIndex - 1];
  const nextPost = data.allPosts.edges[thisPostIndex + 1];

  let blog = false;

  if (frontmatter.author) blog = true;

  let canonical_url = null;

  if (frontmatter.canonical_base_url) {
    canonical_url = frontmatter.canonical_base_url + frontmatter.path;
  } else {
    canonical_url = props.location.href;
  }

  let card = null;

  if (frontmatter.card) {
    card = `https://www.boxysuite.com/cards/${frontmatter.card}`;
  }

  return (
    <LayoutWrapper>
      <SEO
        title={frontmatter.metaTitle || frontmatter.title}
        description={frontmatter.excerpt}
        canonical={canonical_url}
        card={card}
      />
      <SiteHeader dark />
      <LayoutSection small>
        <H1 color="ultra_dark">
          <Link to={frontmatter.path}>{frontmatter.title}</Link>
        </H1>
        {frontmatter.legal && <H4>Last updated on {frontmatter.date}</H4>}
        {blog && <PostAuthor frontmatter={frontmatter} />}
        <BoxySuiteOffer />
        <PageBody dangerouslySetInnerHTML={{ __html: html }} />
        {blog && (
          <BlogPostFooter>
            <Buttons>
              <Button
                small
                href={createTwitterLink(
                  `${frontmatter.title}, by @${authors[frontmatter.author].twitter}`,
                  `https://boxysuite.com${frontmatter.path}`
                )}
                color="accent2"
                id="3"
              >
                <IconTwitter />
                Share this post
              </Button>

              <Button small href="/newsletter" color="accent3">
                <IconSubscribe />
                Subscribe
              </Button>
            </Buttons>

            {nextPost && (
              <P2 style={{ marginTop: "2em" }}>
                Read next: <Link to={nextPost.node.frontmatter.path}>{nextPost.node.frontmatter.title}</Link>
              </P2>
            )}
          </BlogPostFooter>
        )}
      </LayoutSection>
      <Footer />
    </LayoutWrapper>
  );
}

const BoxySuiteOffer = () => {
  const [showOffer, setShowOffer] = useState(false);
  const [appleUser, setAppleUser] = useState(false);
  const [offerDismissed, setOfferDismissed] = useState(false);

  useEffect(() => {
    setAppleUser(isAppleUserFn());
    setOfferDismissed(localStorage.offerDismissed ? localStorage.offerDismissed : false);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!offerDismissed && appleUser) {
        setShowOffer(true);
      }
    }, 4000);
    return () => clearTimeout(timeout);
  }, [offerDismissed, appleUser]);

  function handleClose() {
    setShowOffer(false);
    localStorage && localStorage.setItem("offerDismissed", true);
  }

  return (
    <OfferCard>
      <div style={showOffer ? { bottom: 0, opacity: 1 } : { bottom: -200, opacity: 0 }}>
        <StyledClose
          color="white"
          style={{ opacity: 0.5, top: 6, right: 6, width: 24 }}
          onClick={() => handleClose()}
        />
        <div>
          <H3 center lineHeight="1.2" margin="0 0 0.2em" color="white">
            ⚡️ Supercharge your Gmail productivity
          </H3>
          <P1 center lineHeight="1" margin="0 0 0.6em" color="white">
            The best Gmail client according to 9to5Mac is <Highlight>on sale</Highlight>!
          </P1>
          <Button color="white" href="/?coupon=9to5-sale" onClick={() => handleClose()}>
            Get it now
          </Button>
        </div>
        <div />
      </div>
    </OfferCard>
  );
};

function isAppleUserFn() {
  const browser = window && window.navigator && window.navigator.platform;
  if (browser) {
    return /(Mac|iPhone|iPod|iPad)/i.test(browser);
  }
  return false;
}

const Highlight = styled.span`
  display: inline-block;
  position: relative;
  z-index: 2;
  /* color: #443d1a; */
  ::before {
    content: "";
    position: absolute;
    top: 0px;
    left: -3px;
    right: -3px;
    bottom: 0px;
    background: hsla(50, 100%, 65%, 0.3);
    z-index: -1;
    transform: skewX(-8deg) rotate(-0.5deg);
    border-radius: 3px;
  }
`;

const OfferCard = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: env(safe-area-inset-bottom);
  display: flex;
  padding: 1em;
  z-index: 99;
  > div {
    transition: 0.8s all cubic-bezier(0.68, 0, 0.265, 1.25);
    display: flex;
    margin: auto;
    text-align: center;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    width: 700px;
    max-width: 100%;
    background: linear-gradient(${colors.accent1_gradient});
    padding: 1.5em;
    border-radius: 0.6em;
    position: relative;
  }
  .button,
  .button a {
    @media (max-width: 640px) {
      width: 100%;
    }
  }
`;

export const pageQuery = graphql`
  query($path: String!) {
    thisPost: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        metaTitle
        legal
        card
        author
        excerpt
      }
    }
    allPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/blog/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            author
          }
        }
      }
    }
  }
`;
