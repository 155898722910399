import React, { useState, useEffect } from "react";
import PopupWrapper from "./ui/popupWrapper";
import Card from "./layout/Card";
import H3 from "./typography/H3";
import P1 from "./typography/P1";
import SubscribeInput from "./SubscribeInput";
import Close from "../../assets/glyphs/close.svg";
import styled from "styled-components";
import colors from "./style/colors";
import Checkmark from "../../assets/glyphs/checkmark.svg";

const StyledClose = styled(Close)`
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
  width: 1.5em;
  path {
    fill: ${colors.light};
  }
  :hover {
    path {
      fill: ${colors.grey};
    }
  }
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
  display: inline-block;
  text-align: left;
  line-height: 2;
  svg {
    position: relative;
    bottom: -0.2em;
    margin-right: 0.5em;
  }
`;

const ExitPopup = props => {
  const [exitPopupDismissed, setExitPopupDismissed] = useState(false);
  const [savedPopupDismissed, setSavedPopupDismissed] = useState(null);
  const [exitIntent, setExitIntent] = useState(false);

  const {
    active = true,
    title,
    body,
    width = "32em",
    delay = 3000,
    list,
    label,
    confirmation,
    listId,
    popupKey,
    debug
  } = props;

  useEffect(() => {
    setSavedPopupDismissed(localStorage.getItem(`popup_dismissed:${popupKey}`));
  }, []);

  function showPopup(event, active) {
    if (event.toElement === null && active) {
      setExitIntent(true);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      window.addEventListener("mouseout", e => showPopup(e, active));
    }, delay);
    return () => {
      window.removeEventListener("mouseout", e => showPopup(e, active));
      clearTimeout(timer);
    };
  }, [active]);

  return (
    <PopupWrapper
      top
      show={(!exitPopupDismissed && exitIntent && savedPopupDismissed == null) || debug}
      nomobile
    >
      <Card center width={width}>
        <StyledClose
          onClick={() => {
            localStorage.setItem(`popup_dismissed:${popupKey}`, true);
            setExitPopupDismissed(true);
          }}
        />
        <H3 style={{ fontSize: "1.4em", marginBottom: ".4em", marginRight: "1.5em" }}>{title}</H3>
        <P1 style={{ fontSize: "1em" }}>
          {body && body}
          {list && (
            <List>
              {list.map((l, i) => (
                <li key={i}>
                  <Checkmark />
                  {l}
                </li>
              ))}
            </List>
          )}
        </P1>
        {}
        <SubscribeInput label={label} confirmation={confirmation} listId={listId} />
      </Card>
    </PopupWrapper>
  );
};

export default ExitPopup;
