import React, { useState, useEffect } from "react";
import styled from "styled-components";
import http from "../utils/http";
import validateEmail from "../utils/validateEmail";
import Button from "./ui/Button";
import colors from "./style/colors";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1em;
  * {
    text-align: left;
  }
`;

const StyledTextInput = styled.input`
  flex: 1 1 auto;
  resize: none;
  padding: 0.55em 1em;
  font-size: 1.1em;
  color: ${colors.dark};
  font-weight: 500;
  border-radius: 0.3em;
  border: none;
  background: ${colors.lighter};
  outline: none;
  min-height: 0;
  height: auto;
  height: 2.2em;
  margin-right: 0.5em;
  overflow: hidden;
  ::placeholder {
    color: ${colors.grey};
  }
`;

const FormError = styled.div`
  min-height: 1.8em;
  font-size: 0.9em;
  margin-top: 0.4em;
  margin-bottom: -1.8em;
  text-align: center;
  :not(:empty) {
    color: ${props => (props.warning ? "#ff5959" : "#43bc56")};
  }
`;

const SubscribeInput = props => {
  const { formOnScreen, label, confirmation, listId } = props;

  const [email, setEmail] = useState("");
  const [formMessage, setFormMessage] = useState({ text: "_", warning: false });

  useEffect(() => {
    const storedEmail = localStorage.getItem("user_email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, [formOnScreen]);

  useEffect(() => {
    localStorage.setItem("user_email", email);
  }, [email]);

  useEffect(() => {
    setFormMessage({ text: "", warning: false });
  }, [formOnScreen]);

  function handleChange(event) {
    setEmail(event.target.value);
  }

  function subscribe() {
    if (!validateEmail(email)) {
      setFormMessage({ text: "You need to insert a valid email.", warning: true });
      return;
    }

    http
      .post("https://api.unread.it/mailblast/subscribe", {
        email,
        list_id: listId
      })
      .then(response => {
        setFormMessage({ text: confirmation, warning: false });
      })
      .catch(error => {
        if (error.response) {
          error.response
            .json()
            .then(serverErrors => {
              setFormMessage({ text: serverErrors.join("/n"), warning: true });
            })
            .catch(() => {
              setFormMessage({ text: "Sorry, there seems to be a problem.", warning: true });
            });
        } else {
          setFormMessage({ text: "Sorry, there seems to be a problem.", warning: true });
        }
      });
  }

  return (
    <>
      <FormWrapper>
        <StyledTextInput
          placeholder="Your email address"
          value={email}
          onChange={handleChange}
          onKeyPress={e => {
            if (e.key === "Enter") {
              subscribe(listId);
            }
          }}
        />
        <Button small solid color="accent1" onClick={subscribe}>
          {label}
        </Button>
      </FormWrapper>
      <FormError warning={formMessage.warning}>{formMessage.text}</FormError>
    </>
  );
};

export default SubscribeInput;
