import styled from "styled-components";
import colors from "../style/colors";

const H4 = styled.h4`
  font-size: 1.2em;
  text-align: ${props => (props.center ? "center" : "left")};
  color: ${props => (props.color ? colors[props.color] : colors.ultra_dark)};
  margin: 0.3em 0 0.8em;
  font-weight: 700;
  line-height: 1.55;
  ${props => {
    if (props.gradient) {
      return `
        background: -webkit-linear-gradient(${colors.accent1_gradient});
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `;
    }
  }}
`;

export default H4;
